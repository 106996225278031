import React from 'react'
import PropTypes from 'prop-types'
import { ReelIconHome } from '../ctIcons'
import styles from './style.module.scss'
import dynamic from 'next/dynamic'
import CustomLink from '../customLink'
import { allRoutes } from '@shared/constants/allRoutes'

const ReelSwiper = dynamic(() => import('./reelSwiper'), { ssr: false })

function ReelsHomePage({ isArticle = false }) {
  return (
    <div className={styles.shortsContainer}>
        <>
          <div className="d-flex align-items-center justify-content-between mb-2 ">
            <div className="d-flex align-items-center gap-2">
              <span className='text-primary'><ReelIconHome /></span>
              <h6 className="text-uppercase mb-0">Shorts</h6>
            </div>
            <CustomLink href={allRoutes.reels}>
              <div className="text-primary">View More</div>
            </CustomLink>
          </div>
          <p className="text-gray-600 text-sm mb-6">Your daily dose of cricket!</p>
        </>

      <ReelSwiper isArticle={isArticle}/>
    </div>
  )
}

ReelsHomePage.propTypes = {
  isArticle: PropTypes.bool
}

export default ReelsHomePage
